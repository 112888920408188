import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {Row, Col, Container} from 'reactstrap'
import { FaStar } from "react-icons/fa";

let Review = ({review}) => (
  <Col md={4} className="py-2 d-flex align-items-start">
    <img src={review.profile_photo_url} style={{objectFit: "contain"}} className="mr-3" height="50px"/>
    <div>
      <div className="font-weight-bold">{review.author_name}</div>
      {[...Array(review.rating)].map(() => (
        <FaStar color="#f76900"/>
      ))}
      <div className="text-secondary small">{review.relative_time_description}</div>
      <p className="small">{review.text.substring(0,300)}</p>
    </div>
  </Col>
)

export default ({}) => {
  const data = useStaticQuery(graphql`
    query ReviewsQuery {
      googlePlacesPlace {
        name
        rating
      }
      allGooglePlacesReview {
        edges {
          node {
            author_name
            rating
            text
            relative_time_description
            profile_photo_url
          }
        }
      }
    }
  `)
  return (
    <Container className="py-5">
      <h2 className="text-primary text-center">GOOGLE REVIEWS</h2>
      <Row>
        {data.allGooglePlacesReview.edges.slice(0,6).map(review => (
          <Review review={review.node}/>
        ))}
      </Row>
    </Container>
  )
}
