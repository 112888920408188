import React from "react"
import {Container} from 'reactstrap'
import styled from 'styled-components'
import bg from '../images/welcome-bg.png'
import {ukSite} from './utils'

let HomeVideo = styled.div`
  background: url(${bg});
  background-size: contain;
  color: white;
  h3, h2 {
    color: white;
  }

`

let Iframe = styled.iframe`
  height: 300px;
  border: none;
  @media ${props => props.theme.breakpoints.md} {
    height: 400px;
  }
  @media ${props => props.theme.breakpoints.md} {
    height: 600px;
  }
`

let VimeoIframe = styled.iframe`
  width: 95%;
  height: 250px;
  @media ${props => props.theme.breakpoints.md} {
    height: 500px;
  }
`

export default ({title}) => {
  let common = {
    width: '75%',
    height: '500',
    frameBorder: '0',
    allowFullScreen: true
  }

  return (
    <HomeVideo>
      <Container className="py-4">
        <div className="text-center">
          <h2>{title}</h2>
          <div className="py-2 py-md-4">
            {ukSite() ? (
              <VimeoIframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/510469563?h=bbf97ee4c0&portrait=0&byline=0"
                allowfullscreen
                {...common}
              />
            ) : (
              <Iframe
                src="https://www.youtube.com/embed/KUSlDKDLU-k"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                {...common}
              />
            )}
          </div>
          <h2>CLICK ABOVE TO WATCH OUR VIDEO</h2>
        </div>
      </Container>
    </HomeVideo>
  )
}
