import React from 'react'
import Instagram from './instagram'
import styled from 'styled-components'
import {Container, Col, Row} from 'reactstrap'

let HomeText = styled.div`
  background: #375893;
  color: white;
  h1, h2, h3, h4 {
    color: white;
  }
`

export default ({content}) => (
  <HomeText>
    <Container className="py-5">
      <Row>
        <Col md="8">
          <div dangerouslySetInnerHTML={{ __html: content }}/>
        </Col>
        <Col md="4">
          <h2>WE ARE SOCIAL</h2>
          <Instagram/>
        </Col>
      </Row>
    </Container>
  </HomeText>
)
