import React, {useEffect, useState} from 'react'
import {useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import SocialIcons from './socialIcons'
import { graphql } from 'gatsby'
import axios from 'axios'

let Post = ({insta}) => (
  <div className="my-3">
    <img src={insta.media_url} style={{maxWidth: '100%'}} className="mb-3"/>
    {insta.caption}
  </div>
)

export default () => {
  const [insta, setInsta] = useState(false);
  useEffect(() => {
    let access_token = 'EAAGnNEGazSQBAB9oXPwZBlVYO61OQz358NDylR2rQTTAcbKph6dPygMJIOF5sZA9auSdrLLfZAX6JqwHpp0U3XDV4QCRB5mZBlnAwvW97EBYF86LTepQzGEHZBEJQX9ElOWktleAOUfAniqYZC4fVIQY8GuIWNoIyg6RLpKmd9iAZDZD'
    let url = `https://graph.facebook.com/v7.0/17841407434917804/media?fields=media_url%2Ccaption&limit=1&access_token=${access_token}`
    axios.get(url)
      .then(data => setInsta(data.data.data[0]))
  }, []);
  return (
    <>
      {insta ? <Post insta={insta}/> : ''}
      <SocialIcons/>
    </>
  )
}
