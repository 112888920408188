import React from "react"
import HomeVideo from '../components/homeVideo'
import HomeCta from '../components/homeCta'
import HomeText from '../components/homeText'
import Layout from "../components/layout"
import HomeJobs from '../components/homeJobs'
import Reviews from '../components/reviews'
import website from '../data/website'
import { graphql } from 'gatsby'

export default ({data}) => {
  const {title, content, acf} = data.wordpressPage
  return (
    <Layout title={website.name}>
      <HomeVideo title={acf.subtitle}/>
      <HomeCta/>
      <HomeJobs/>
      <HomeText content={content}/>
      <Reviews/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(slug: {eq: "home"}) {
      title
      content
      acf {
        subtitle
      }
    }
  }
`
