import React from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'reactstrap'
import Link from './link'
import {ukSite} from './utils'

let HomeCta = styled.div`
  background: #e2e2e2;
`

const LinkButton = ({url, text}) => (
  <Col lg={{size: "4", order: 2}} className="mb-2 mb-lg-0 d-flex justify-content-center">
    <Link to={url} className="btn btn-primary btn-lg btn-block">
      {text}
    </Link>
  </Col>
)

export default () => (
  <HomeCta>
    <Container className="py-4">
      <Row className="d-flex justify-content-center align-items-center">
        {ukSite() ? (
          <LinkButton url="/job-seekers/life-in-australia/" text="LOOKING FOR A JOB?"/>
        ) : (
          <>
            <LinkButton url="/mechanics" text="LOOKING FOR WORK?"/>
            <LinkButton url="/workshops" text="NEED A MECHANIC?"/>
          </>
        )}
      </Row>
    </Container>
  </HomeCta>
)
